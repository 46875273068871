<template>
  <div class="fontRule">
    <div class="titlebox">龙腾捷旅售卖规则</div>
    <el-row style="margin-top: 10px">
      <el-col class="leftlabelbox" :span="6"> 请选择 </el-col>
      <el-col :span="12">
        <el-select
          size="mini"
          class="marginright10"
          style="width: 400px"
          v-model="ltjlSellData.jlCode"
        >
          <el-option
            v-for="item in codeOptons"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      ltjlSellData: {
        jlCode: "",
        platform: "9",
      },
      codeOptons: [
        { code: "LO3C", name: "1人入住 不含早 " },
        { code: "LO46", name: "1人入住 不含早 当天17点前预订 " },
        { code: "LO4G", name: "1人入住 不含早 当天21点前预订 " },
        { code: "LO64", name: "1人入住 不含早 提前1天预订 " },
        { code: "LO6Y", name: "1人入住 不含早 提前1天17点前预订 " },
        { code: "LO78", name: "1人入住 不含早 提前1天21点前预订 " },
        { code: "LOV4", name: "1人入住 不含早 连住2天 " },
        { code: "LOVY", name: "1人入住 不含早 当天17点前预订 连住2天 " },
        { code: "LOW8", name: "1人入住 不含早 当天21点前预订 连住2天 " },
        { code: "LOXW", name: "1人入住 不含早 提前1天预订 连住2天 " },
        { code: "LOYQ", name: "1人入住 不含早 提前1天17点前预订 连住2天 " },
        { code: "LOZ0", name: "1人入住 不含早 提前1天21点前预订 连住2天 " },
        { code: "LVT4", name: "1人入住 不含早 2间起订 " },
        { code: "LVTY", name: "1人入住 不含早 当天17点前预订 2间起订 " },
        { code: "LVU8", name: "1人入住 不含早 当天21点前预订 2间起订 " },
        { code: "LVVW", name: "1人入住 不含早 提前1天预订 2间起订 " },
        { code: "LVWQ", name: "1人入住 不含早 提前1天17点前预订 2间起订 " },
        { code: "LVX0", name: "1人入住 不含早 提前1天21点前预订 2间起订 " },
        { code: "LWKW", name: "1人入住 不含早 连住2天 2间起订 " },
        {
          code: "LWLQ",
          name: "1人入住 不含早 当天17点前预订 连住2天 2间起订 ",
        },
        {
          code: "LWM0",
          name: "1人入住 不含早 当天21点前预订 连住2天 2间起订 ",
        },
        { code: "LWNO", name: "1人入住 不含早 提前1天预订 连住2天 2间起订 " },
        {
          code: "LWOI",
          name: "1人入住 不含早 提前1天17点前预订 连住2天 2间起订 ",
        },
        {
          code: "LWOS",
          name: "1人入住 不含早 提前1天21点前预订 连住2天 2间起订 ",
        },
        { code: "NT94", name: "1人入住 单早 " },
        { code: "NT9Y", name: "1人入住 单早 当天17点前预订 " },
        { code: "NTA8", name: "1人入住 单早 当天21点前预订 " },
        { code: "NTBW", name: "1人入住 单早 提前1天预订 " },
        { code: "NTCQ", name: "1人入住 单早 提前1天17点前预订 " },
        { code: "NTD0", name: "1人入住 单早 提前1天21点前预订 " },
        { code: "NU0W", name: "1人入住 单早 连住2天 " },
        { code: "NU1Q", name: "1人入住 单早 当天17点前预订 连住2天 " },
        { code: "NU20", name: "1人入住 单早 当天21点前预订 连住2天 " },
        { code: "NU3O", name: "1人入住 单早 提前1天预订 连住2天 " },
        { code: "NU4I", name: "1人入住 单早 提前1天17点前预订 连住2天 " },
        { code: "NU4S", name: "1人入住 单早 提前1天21点前预订 连住2天 " },
        { code: "O0YW", name: "1人入住 单早 2间起订 " },
        { code: "O0ZQ", name: "1人入住 单早 当天17点前预订 2间起订 " },
        { code: "O100", name: "1人入住 单早 当天21点前预订 2间起订 " },
        { code: "O11O", name: "1人入住 单早 提前1天预订 2间起订 " },
        { code: "O12I", name: "1人入住 单早 提前1天17点前预订 2间起订 " },
        { code: "O12S", name: "1人入住 单早 提前1天21点前预订 2间起订 " },
        { code: "O1QO", name: "1人入住 单早 连住2天 2间起订 " },
        { code: "O1RI", name: "1人入住 单早 当天17点前预订 连住2天 2间起订 " },
        { code: "O1RS", name: "1人入住 单早 当天21点前预订 连住2天 2间起订 " },
        { code: "O1TG", name: "1人入住 单早 提前1天预订 连住2天 2间起订 " },
        {
          code: "O1UA",
          name: "1人入住 单早 提前1天17点前预订 连住2天 2间起订 ",
        },
        {
          code: "O1UK",
          name: "1人入住 单早 提前1天21点前预订 连住2天 2间起订 ",
        },
        { code: "173P4", name: "2人入住 不含早 " },
        { code: "173PY", name: "2人入住 不含早 当天17点前预订 " },
        { code: "173Q8", name: "2人入住 不含早 当天21点前预订 " },
        { code: "173RW", name: "2人入住 不含早 提前1天预订 " },
        { code: "173SQ", name: "2人入住 不含早 提前1天17点前预订 " },
        { code: "173T0", name: "2人入住 不含早 提前1天21点前预订 " },
        { code: "174GW", name: "2人入住 不含早 连住2天 " },
        { code: "174HQ", name: "2人入住 不含早 当天17点前预订 连住2天 " },
        { code: "174I0", name: "2人入住 不含早 当天21点前预订 连住2天 " },
        { code: "174JO", name: "2人入住 不含早 提前1天预订 连住2天 " },
        { code: "174KI", name: "2人入住 不含早 提前1天17点前预订 连住2天 " },
        { code: "174KS", name: "2人入住 不含早 提前1天21点前预订 连住2天 " },
        { code: "17BEW", name: "2人入住 不含早 2间起订 " },
        { code: "17BFQ", name: "2人入住 不含早 当天17点前预订 2间起订 " },
        { code: "17BG0", name: "2人入住 不含早 当天21点前预订 2间起订 " },
        { code: "17BHO", name: "2人入住 不含早 提前1天预订 2间起订 " },
        { code: "17BII", name: "2人入住 不含早 提前1天17点前预订 2间起订 " },
        { code: "17BIS", name: "2人入住 不含早 提前1天21点前预订 2间起订 " },
        { code: "17C6O", name: "2人入住 不含早 连住2天 2间起订 " },
        {
          code: "17C7I",
          name: "2人入住 不含早 当天17点前预订 连住2天 2间起订 ",
        },
        {
          code: "17C7S",
          name: "2人入住 不含早 当天21点前预订 连住2天 2间起订 ",
        },
        { code: "17C9G", name: "2人入住 不含早 提前1天预订 连住2天 2间起订 " },
        {
          code: "17CAA",
          name: "2人入住 不含早 提前1天17点前预订 连住2天 2间起订 ",
        },
        {
          code: "17CAK",
          name: "2人入住 不含早 提前1天21点前预订 连住2天 2间起订 ",
        },
        { code: "1BE0O", name: "2人入住 双早 " },
        { code: "1BE1I", name: "2人入住 双早 当天17点前预订 " },
        { code: "1BE1S", name: "2人入住 双早 当天21点前预订 " },
        { code: "1BE3G", name: "2人入住 双早 提前1天预订 " },
        { code: "1BE4A", name: "2人入住 双早 提前1天17点前预订 " },
        { code: "1BE4K", name: "2人入住 双早 提前1天21点前预订 " },
        { code: "1BESG", name: "2人入住 双早 连住2天 " },
        { code: "1BETA", name: "2人入住 双早 当天17点前预订 连住2天 " },
        { code: "1BETK", name: "2人入住 双早 当天21点前预订 连住2天 " },
        { code: "1BEV8", name: "2人入住 双早 提前1天预订 连住2天 " },
        { code: "1BEW2", name: "2人入住 双早 提前1天17点前预订 连住2天 " },
        { code: "1BEWC", name: "2人入住 双早 提前1天21点前预订 连住2天 " },
        { code: "1BLQG", name: "2人入住 双早 2间起订 " },
        { code: "1BLRA", name: "2人入住 双早 当天17点前预订 2间起订 " },
        { code: "1BLRK", name: "2人入住 双早 当天21点前预订 2间起订 " },
        { code: "1BLT8", name: "2人入住 双早 提前1天预订 2间起订 " },
        { code: "1BLU2", name: "2人入住 双早 提前1天17点前预订 2间起订 " },
        { code: "1BLUC", name: "2人入住 双早 提前1天21点前预订 2间起订 " },
        { code: "1BMI8", name: "2人入住 双早 连住2天 2间起订 " },
        { code: "1BMJ2", name: "2人入住 双早 当天17点前预订 连住2天 2间起订 " },
        { code: "1BMJC", name: "2人入住 双早 当天21点前预订 连住2天 2间起订 " },
        { code: "1BML0", name: "2人入住 双早 提前1天预订 连住2天 2间起订 " },
        {
          code: "1BMLU",
          name: "2人入住 双早 提前1天17点前预订 连住2天 2间起订 ",
        },
        {
          code: "1BMM4",
          name: "2人入住 双早 提前1天21点前预订 连住2天 2间起订 ",
        },
      ],
    };
  },
  created() {},
  methods: {
    getRuleData() {
      return this.ltjlSellData;
    },
  },
};
</script>
  
  <style lang="less" scoped>
.fontRule {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.leftlabelbox {
  text-align: right;
  padding-right: 20px;
  min-height: 28px;
  line-height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.radiobox {
  line-height: 28px;
  height: 28px;
}

.titlebox {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
  margin-left: 50px;
}

.width88 {
  width: 95px;
}

.marginlar {
  margin-left: 10px;
  margin-right: 10px;
}

.margintop20 {
  margin-top: 20px;
}

.marginleft10 {
  margin-left: 10px;
}

.marginright10 {
  margin-right: 10px;
}
</style>
  