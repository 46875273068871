<template>
  <!-- 批量设置售卖房型 -->
  <div>
    <el-dialog
      title="批量设置售卖房型"
      width="880px"
      :visible.sync="dialogFormVisible"
      top="10rem"
      :close-on-click-modal="false"
       @closed="cleanDataFn"
    >
      <div class="rommListBox">
        <div class="leftbox">房型</div>
        <div class="rightbox">
          <div style="margin-bottom: 10px">
            <el-checkbox
              style="font-weight: 400"
              :indeterminate="indeterminate"
              v-model="ischeckAll"
              @change="handleCheckAllChange"
            >
              全选
            </el-checkbox>
            <el-checkbox
              style="font-weight: 400"
              v-model="fanCheck"
              @change="handFanCheck"
            >
              反选
            </el-checkbox>
          </div>

          <div
            class="rightboxcontentbox"
            v-for="(item, topIndex) in arrRoomBigList"
            :key="item.roomTypeId"
          >
            <div class="hotelTitleName">
              <el-checkbox
                :indeterminate="item.indeterminate"
                v-model="item.preSelected"
                @change="handleCheckTwoChange(topIndex, item.firstId, $event)"
                >{{ item.roomTypeName }}</el-checkbox
              >
            </div>
            <div
              style="margin-left: 20px"
              v-for="ctem in item.list"
              :key="ctem.roomId"
            >
              <el-checkbox
                style="
                  font-weight: 400;
                  font-size: 12px;
                  margin-top: 5px;
                  font-family: Microsoft YaHei;
                "
                v-model="ctem.preSelected"
                @change="
                  handleCheckThreeChange(
                    topIndex,
                    ctem.roomId,
                    item.firstId,
                    $event
                  )
                "
              >
                {{ ctem.roomName }}
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="dialogFormVisibleMenu">
        <el-menu
          mode="horizontal"
          class="el-menu-demo"
          @select="handleSelect"
          :default-active="index"
        >
          <el-menu-item index="1">房型基础参数</el-menu-item>
          <el-menu-item index="2">分销渠道售卖规则</el-menu-item>
        </el-menu>
      </div>
      <div v-if="index == 1">
        <!-- <el-row style="margin-top: 30px">
          <el-col class="formItemBox" :span="4"> 入住人数 </el-col>
          <el-col :span="18">
            <el-select size="mini" style="width: 88px" v-model="renshuDisable">
              <el-option label="不变" value="不变"></el-option>
              <el-option label="修改为" value="修改为"></el-option>
            </el-select>
            <el-select
              style="width: 88px; margin-left: 10px"
              size="mini"
              v-if="renshuDisable == '修改为'"
              v-model="ruleListBase.maxOccupancy"
            >
              <el-option
                v-for="item in personNumbers"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row> -->
        <el-row style="margin-top: 20px">
          <el-col class="formItemBox" :span="4"> 餐食 </el-col>
          <el-col :span="18">
            <el-select size="mini" style="width: 88px" v-model="canshiDisable">
              <el-option label="不变" value="不变"></el-option>
              <el-option label="修改为" value="修改为"></el-option>
            </el-select>
            <el-select
              style="width: 88px; margin-left: 10px"
              size="mini"
              v-if="canshiDisable == '修改为'"
              v-model="ruleListBase.mealType"
            >
              <el-option
                v-for="item in mealsNumbers"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px"  v-if="canshiDisable == '修改为'">
          <el-col class="formItemBox" :span="4"> 餐食数 </el-col>
          <el-col :span="18">
            <el-select
              style="width: 88px"
              size="mini"
              v-model="ruleListBase.mealCount"
            >
              <el-option
                v-for="item in mealCounts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col class="formItemBox" :span="4"> 适用人群 </el-col>
          <el-col :span="18">
            <el-select size="mini" style="width: 88px" v-model="shiyongDisable">
              <el-option label="不变" value="不变"></el-option>
              <el-option label="修改为" value="修改为"></el-option>
            </el-select>
            <el-select
              style="width: 88px; margin-left: 10px"
              size="mini"
              v-if="shiyongDisable == '修改为'"
              v-model="ruleListBase.limitation"
            >
              <el-option
                v-for="item in shiyongNumbers"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top: 20px">
          <el-col class="formItemBox" :span="4"> 是否限制可见面人群 </el-col>
          <el-col :span="18">
            <el-select size="mini" style="width: 88px" v-model="mianjiDisable">
              <el-option label="不变" value="不变"></el-option>
              <el-option label="修改为" value="修改为"></el-option>
            </el-select>
            <el-radio-group style="margin-left: 10px" size="mini" v-model="formData.mianjiNumber">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-col>
        </el-row> -->
      </div>
      <div v-if="index == 2">
        <XiechengSellRule ref="xiechengsellrule"></XiechengSellRule>
        <YilongSellRule ref="yilongsellrule"></YilongSellRule>
        <FeizhuSellRule ref="feizhusellrule"></FeizhuSellRule>
        <QunaSellRule ref="qunasellrule"></QunaSellRule>
        <MeituanSellRule ref="meituansellrule"></MeituanSellRule>
        <Baidusellrule ref="baidusellrule"></Baidusellrule>
        <Luobosellrule ref="luobosellrule"></Luobosellrule>
        <Fctxsellrule ref="fctxsellrule"></Fctxsellrule>
        <Ltjlsellrule ref="ltjlsellrule"></Ltjlsellrule>
       
      </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { hotelRoomVestTree_api } from "../../../apis/room";
import { batchSetRoomVestBase_api } from "../../../apis/room";
import { batchSetRoomVest_api } from "../../../apis/room";
import XiechengSellRule from "./XiechengSellRule.vue";
import YilongSellRule from "./YilongSellRule.vue";
import FeizhuSellRule from "./FeizhuSellRule.vue";
import QunaSellRule from "./QunaSellRule.vue";
import MeituanSellRule from "./MeituanSellRule.vue";
import Baidusellrule from "./Baidusellrule.vue";
import Luobosellrule from "./Luobosellrule.vue";
import Fctxsellrule from "./Fctxsellrule.vue";
import Ltjlsellrule from "./Ltjlsellrule.vue";
export default {
  components: {
    XiechengSellRule,
    YilongSellRule,
    FeizhuSellRule,
    QunaSellRule,
    MeituanSellRule,
    Baidusellrule,
    Luobosellrule,
    Fctxsellrule,
    Ltjlsellrule
  },
  data() {
    return {
      dialogFormVisible: false,
      fanCheck: false,
      arrRoomBigList: [],
      indeterminate: true,
      ischeckAll: false,
      arrRoomBigList: "",
      hotelSonId: "",
      index: "1",
      ruleListBase: {
        maxOccupancy: "",
        mealType: "",
        mealCount: "",
        limitation: "",
      },

      formData: {
        personNumber: "",
      },
      renshuDisable: "不变",
      canshiDisable: "不变",
      shiyongDisable: "不变",
      mianjiDisable: "不变",
      personNumbers: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
      
      ],
      mealsNumbers: [
        { label: "无早", value: 0 },
        { label: "晚餐", value: 1 },
        { label: "午餐", value: 2 },
        { label: "午+晚餐", value: 3 },
        { label: "早餐", value: 4 },
        { label: "早+晚餐", value: 5 },
        { label: "早+午餐", value: 6 },
        { label: "早+午+晚餐", value: 7 },
         { label: "午/晚二选一", value: 10 },
         { label: "早+ 午/晚二选一", value: 11 },
      ],
      mealCounts: [
        { label: "0", value: 0 },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
        { label: "12", value: 12 },
        { label: "13", value: 13 },
        { label: "14", value: 14 },
        { label: "15", value: 15 },
        { label: "16", value: 16 },
        { label: "17", value: 17 },
        { label: "18", value: 18 },
        { label: "19", value: 19 },
        { label: "20", value: 20 },
        { label: "21", value: 21 },
        { label: "22", value: 22 },
        { label: "23", value: 23 },
        { label: "24", value: 24 },
        { label: "25", value: 25 },
        { label: "26", value: 26 },
        { label: "27", value: 27 },
        { label: "28", value: 28 },
        { label: "29", value: 29 },
        { label: "30", value: 30 },
        { label: "31", value: 31 },
        { label: "32", value: 32 },
        { label: "33", value: 33 },
        { label: "34", value: 34 },
        { label: "35", value: 35 },
        { label: "36", value: 36 },
        { label: "37", value: 37 },
        { label: "38", value: 38 },
        { label: "39", value: 39 },
        { label: "40", value: 40 },
        { label: "41", value: 41 },
        { label: "42", value: 42 },
        { label: "43", value: 43 },
        { label: "44", value: 44 },
        { label: "45", value: 45 },
        { label: "46", value: 46 },
        { label: "47", value: 47 },
        { label: "48", value: 48 },
        { label: "49", value: 49 },
        { label: "50", value: 50 },
        
       
       
      ],
      shiyongNumbers: [
        { label: "不限", value: 0 },
        { label: "外宾", value: 1 },
        { label: "中宾", value: 2 },
        { label: "内宾", value: 3 },
        { label: "男生入住", value: 4 },
        { label: "女生入住", value: 5 },
        { label: "混合入住", value: 6 },
      ],
    };
  },
  methods: {
    open(hotelSonId, selectRoomIdArr) {
       console.log(selectRoomIdArr)
      this.hotelSonId = hotelSonId;
      this.dialogFormVisible = true;
      this.getHotelRoomVestTree(hotelSonId, selectRoomIdArr);
    },
    //获取单个酒店的房型树
    async getHotelRoomVestTree(hotelSonId, selectRoomIdArr) {
     
      let { code, data } = await hotelRoomVestTree_api({
        hotelSonId: hotelSonId,
      });
      if (code == 0) {
        data.forEach((item) => {
          if (item.list) {
            item.list.forEach((ctem) => {
              if (selectRoomIdArr.indexOf(ctem.roomId) != "-1") {
                ctem.preSelected = true;
              }
            });
          }
        });

        this.arrRoomBigList = data;
      }
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
      if (this.index == 1) {
        if (this.renshuDisable == "不变") {
          this.ruleListBase.maxOccupancy = "";
        }
        if (this.canshiDisable == "不变") {
          this.ruleListBase.mealsNumber = "";
        }
        let upData = {
          hotelSonId: this.hotelSonId,
          roomTypeList: this.arrRoomBigList,
          ruleListBase: this.ruleListBase,
        };
        this.batchSetRoomVestBase(upData);
      } else {
        let ruleList = [
          this.$refs.yilongsellrule.getRuleData(),
          this.$refs.feizhusellrule.getRuleData(),
          this.$refs.meituansellrule.getRuleData(),
          this.$refs.qunasellrule.getRuleData(),
          this.$refs.xiechengsellrule.getRuleData(),
          this.$refs.baidusellrule.getRuleData(),
          this.$refs.luobosellrule.getRuleData(),
          this.$refs.fctxsellrule.getRuleData(),
          this.$refs.ltjlsellrule.getRuleData(),
        ];

        let upData = {
          hotelSonId: this.hotelSonId,
          roomTypeList: this.arrRoomBigList,
          ruleList: ruleList,
        };
        //  console.log(JSON.stringify(upData))
        this.batchSetRoomVest(upData);
      }
    },
    //批量修改/售卖房型静态基础数据(根据平台区别
    async batchSetRoomVestBase(upData) {
      let { code, data } = await batchSetRoomVestBase_api(upData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
         this.$parent.getRoomInfoById()
      }
    },

    //批量修改/售卖房型静态数据(根据平台区别)
    async batchSetRoomVest(upData) {
      let { code, data } = await batchSetRoomVest_api(upData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
         this.$parent.getRoomInfoById()
      }
    },

    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    // 一层全选
    handleCheckAllChange(e) {
      let that = this;
      this.ischeckAll = e;
      if (e == true) {
        this.indeterminate = false;
        for (let i = 0; i < that.arrRoomBigList.length; i++) {
          that.arrRoomBigList[i].preSelected = e;
          for (let j = 0; j < that.arrRoomBigList[i].list.length; j++) {
            that.arrRoomBigList[i].list[j].preSelected = e;
          }
        }
      } else {
        this.indeterminate = false;
        for (let i = 0; i < that.arrRoomBigList.length; i++) {
          that.arrRoomBigList[i].preSelected = e;
          for (let j = 0; j < that.arrRoomBigList[i].list.length; j++) {
            that.arrRoomBigList[i].list[j].preSelected = e;
          }
        }
      }
    },
    // 反选
    handFanCheck() {
      console.log(this.arrRoomBigList);

      let countTwo = 0;
      this.arrRoomBigList.forEach((item) => {
        let countOne = 0;

        if (item.list == [] || item.list == null || item.list.length == 0) {
          console.log("满足空数组");
          item.preSelected = !item.preSelected;
          item.indeterminate = false;
          if (item.preSelected == true) {
            countTwo++;
          }
        }
        console.log(item);
        if (item.list && item.list.length > 0) {
          item.list.forEach((ctem) => {
            ctem.preSelected = !ctem.preSelected;
            if (ctem.preSelected) {
              countOne++;
            }
          });

          if (countOne == item.list.length) {
            item.preSelected = true;
            countTwo++;
            item.indeterminate = false;
          } else if (countOne == 0) {
            item.preSelected = false;
            item.indeterminate = false;
          } else {
            item.preSelected = false;
            item.indeterminate = true;
          }
        }
      });
      if (countTwo == this.arrRoomBigList.length) {
        this.ischeckAll = true;
        this.indeterminate = false;
      } else if (countTwo == 0) {
        this.ischeckAll = false;
        this.indeterminate = false;
      } else {
        this.ischeckAll = false;
        this.indeterminate = true;
      }
    },
    // 二层全选
    handleCheckTwoChange(index, id, e) {
      let that = this;
      that.arrRoomBigList[index].preSelected = e;
      if (e == false) {
        this.arrRoomBigList[index].indeterminate = false;
      }
      if (that.arrRoomBigList[index].list) {
        for (let i = 0; i < that.arrRoomBigList[index].list.length; i++) {
          that.arrRoomBigList[index].list[i].preSelected = e;
        }
      }
      this.getIsCheckAll();
    },
    // 三层选择
    handleCheckThreeChange(topIndex, sonId, topId, e) {
      let that = this;
      let childrenArray = that.arrRoomBigList[topIndex].list;
      let tickCount = 0;
      let unTickCount = 0;
      let len = childrenArray.length;
      for (let i = 0; i < len; i++) {
        if (sonId == childrenArray[i].id) {
          childrenArray[i].preSelected = e;
        }
        if (childrenArray[i].preSelected == true) {
          tickCount++;
        }
        if (childrenArray[i].preSelected == false) {
          unTickCount++;
        }
      }
      if (tickCount == len) {
        that.arrRoomBigList[topIndex].preSelected = true;
        that.arrRoomBigList[topIndex].indeterminate = false;
      } else if (unTickCount == len) {
        that.arrRoomBigList[topIndex].preSelected = false;
        that.arrRoomBigList[topIndex].indeterminate = false;
      } else {
        that.arrRoomBigList[topIndex].preSelected = false;
        that.arrRoomBigList[topIndex].indeterminate = true;
      }
      that.getIsCheckAll();
    },

    //选择引起的上层全选框变动
    getIsCheckAll() {
      let that = this;
      let tickCount = 0,
        unTickCount = 0,
        ArrLength = that.arrRoomBigList.length;
      for (let j = 0; j < ArrLength; j++) {
        if (that.arrRoomBigList[j].preSelected == true) {
          tickCount++;
        }
        if (that.arrRoomBigList[j].preSelected == false) {
          unTickCount++;
        }
      }
      if (tickCount == ArrLength) {
        //二级全勾选
        that.ischeckAll = true;
        that.indeterminate = false;
      } else if (unTickCount == ArrLength) {
        //二级全不勾选
        that.ischeckAll = false;
        that.indeterminate = false;
      } else {
        that.ischeckAll = false;
        that.indeterminate = true; //添加一级不确定状态
      }
    },

    //设置标签
    handleSelect(val) {
    
      this.index = val;
    },
      cleanDataFn(){
      Object.assign(this.$data, this.$options.data());
    }
  },
};
</script>

<style lang="less" scoped>
.rightboxcontentbox {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}
.dialogFormVisibleMenu {
  margin-top: 20px;
  /deep/ .el-menu-demo {
    height: 22px;
  }
  /deep/ .el-menu-item {
    height: 22px;
    line-height: 22px;
    font-size: 12px;
  }
  /deep/ .is-active {
    background-color: #256def !important ;
    color: #fff !important;
  }
}
.formItemBox {
  min-height: 28px;
  line-height: 28px;
  text-align: right;
  padding-right: 20px;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.rommListBox {
  height: 160px;
  overflow: auto;
}
.leftbox {
  display: inline-block;
  width: 50px;
  vertical-align: top;
  margin-left: 30px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  margin-bottom: 20px;
}
.rightbox {
  display: inline-block;
  width: 700px;
  vertical-align: top;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-left: 20px;
}
.rightboxcontentbox {
  display: inline-block;
  width: 45%;
  vertical-align: top;
}
.hotelTitleName {
  // margin-bottom: 15px;
  margin: 7.5px 0;

  /deep/ .el-checkbox__label {
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 18px;
  }
}
/deep/ .el-checkbox__label {
  font-size: 12px;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #409eff;
}
</style>>
