<template>
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="410px"
      @closed="cleanDataFn"
    >
      <div slot="title" class="header-title">
        <span style="font-size: 16px">设置酒店销售佣金</span>
      </div>
      <div style="text-align: center">
        <el-form
          size="mini"
          label-width="110px"
          :model="handleData"
          :rules="rules"
          ref="elform"
        >
          <!-- <el-form-item label="选择人员" prop="id">
            <el-select v-model="handleData.id" filterable style="width: 250px">
              <el-option
                v-for="item in luoboPersonList"
                :key="item.id"
                :label="item.name + '(' + item.phone + ')'"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
           <el-form-item label="手机号" prop="id">
            <el-input
              v-model="handleData.id"
              placeholder="请输入手机号"
              style="width: 250px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="设置佣金比例" prop="commissionRatio">
            <el-input
              v-model="handleData.commissionRatio"
              placeholder="请输入0~1之间的比例"
              style="width: 250px"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getLuoboPersonList_api,setHotelSaleCommission_api } from "../../../apis/room";
export default {
  name: "Hotelsalecommissiondia",
  data() {
    return {
      dialogFormVisible: false,
      handleData: {
        hotelSonId: "",
        commissionRatio: "",
        id: "",
      },
      luoboPersonList: [], // 人员列表
      rules: {
        id: [
          { required: true, message: "请选择人员", trigger: "blur" },
          { required: true, message: "请选择人员", trigger: "change" },
        ],
        commissionRatio: [
          { required: true, message: "请输入佣金比例", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getLuoboPersonList();
  },
  methods: {
    open(hotelSonId) {
      this.dialogFormVisible = true;
      this.handleData.hotelSonId = hotelSonId;
    },

    cleanDataFn() {
     // Object.assign(this.$data, this.$options.data());
      this.dialogFormVisible = false;
    },
    getLuoboPersonList() {
      getLuoboPersonList_api({
        page: 1,
        rows: 99999,
      }).then((res) => {
        this.luoboPersonList = res.data.records;
      });
    },
    cleanFn() {
      this.dialogFormVisible = false;
    },
    handEditData() {
      this.$refs.elform.validate((valid) => {
        if (valid) {
          setHotelSaleCommission_api(this.handleData).then((res) => {
            this.$message.success("设置佣金成功");
            this.handleData = {
              hotelSonId: "",
              commissionRatio: "",
              id: "",
            };
            this.dialogFormVisible = false;
          });
        }else{
            this.$message.error("请填写完整信息")
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>
