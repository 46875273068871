<template>
  <div>
    <el-dialog title="新增礼包" :visible.sync="modalVisible" width="800px"  top="50px">
      <el-form
        label-width="80px"
        :model="elongGiftInfo"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="礼包名称" prop="name">
          <el-input
            v-model="elongGiftInfo.name"
            placeholder="长度不能超过15个字符"
          ></el-input>
        </el-form-item>
        <el-form-item label="礼包类型" prop="giftClassify">
          <el-radio-group v-model="elongGiftInfo.giftClassify">
            <el-radio :label="0">礼包</el-radio>
            <el-radio :label="1">套餐</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="礼包内容" prop="description">
          <el-input
            type="textarea"
            v-model="elongGiftInfo.description"
            placeholder="长度不能超 过500个字符"
          ></el-input>
        </el-form-item>
        <el-form-item label="适用星期">
          <el-checkbox
            :indeterminate="weekIndeterminate"
            v-model="ischeckAllWeek"
            style="margin-right: 5px"
            @change="changeCheckAllWeek"
          >
            全选
          </el-checkbox>
          <el-checkbox-group
            v-model="weekDayList"
            style="display: inline-block"
            @change="changeWeekDayList"
          >
            <el-checkbox
              v-for="etem in weeks"
              :label="etem.id"
              :key="etem.id"
              style="margin-right: 5px"
              >{{ etem.week }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="适用日期">
          <el-date-picker
            v-model="elongGiftInfo.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="生效类型" prop="validType">
          <el-radio-group v-model="elongGiftInfo.validType">
            <el-radio :label="1">入住生效</el-radio>
            <el-radio :label="2">离店生效</el-radio>
            <el-radio :label="3">在店生效</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="总价值" prop="giftValue">
          <el-input
            v-model="elongGiftInfo.giftValue"
            placeholder="上限1500"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="赠送方式" prop="giveType">
          <el-radio-group v-model="elongGiftInfo.giveType">
            <el-radio :label="0">每间房包含一次</el-radio>
            <el-radio :label="1">每间房每日包含</el-radio>
            <el-radio :label="2">
              其他
              <el-input
                v-if="elongGiftInfo.giveType == 2"
                v-model="elongGiftInfo.giveTypeRemark"
                placeholder="请输入"
              ></el-input>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="礼包内容">
          <div
            v-for="(item, index) in cascaderValue"
            :key="index"
            style="margin-bottom: 8px"
          >
            <el-cascader
              v-model="item.cascaderVal"
              placeholder="礼包内容"
              :options="giftDetailOptions"
              :props="{
                children: 'subDetail',
                label: 'subName',
                value: 'index',
              }"
              filterable
              clearable
              style="width: 300px"
              @change="changeCascaderValue"
            ></el-cascader>
            <el-input
              v-model="item.value"
              placeholder="价值"
              style="width: 100px; margin-left: 10px"
            ></el-input>
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              @click="addGiftDetail(index)"
            ></el-button>
            <el-button
              type="primary"
              plain
              @click="removeGiftDetail(index)"
              icon="el-icon-minus"
              v-if="index > 0"
            ></el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { giftDetailOptions_api, addElongGift_api } from "@/apis/room";
export default {
  name: "Addelonggiftmodal",
  data() {
    return {
      modalVisible: false,
      elongGiftInfo: {
        name: "", //礼包名称，长度不能超过15 个字符
        description: "", //礼包类型描述，长度不能超 过500个字符
        end: "", //入住有效日期 结束 2021-03-24 00:00:00
        giftClassify: 0, //礼包类型 0-礼包 1-套餐
        giftDetail: [
          {
            detailName: "",
            detailType: "",
            subDetail: [{ code: "", subName: "", value: "" }],
          },
        ], //礼包详情
        giftDetails: "", //礼包内容 至少选择一个类型；所有类型价值总和等于总价值，有校验，不等则当错误数据处理
        giftValue: "", //总价值 上限1500
        giveType: 0, //赠送方式 0-每间房包含一次、1-每间房每日包含、2-其他
        giveTypeRemark: "", //GiveType=2 的时候自定义 此参数
        id: "",

        roomSonId: "", //售卖房型id
        start: "", //入住有效日期 开始 2021-03-15 00:00:00
        validType: 1, //生效类型 1:入住生效 2:离 店生效 3:在店生效
        weeklyIndex: "1111111", //适应日期 1111111 第一位为0表示周一不可用
        date: [],
      },
      weeks: [
        {
          id: 1,
          week: "一",
        },
        {
          id: 2,
          week: "二",
        },
        {
          id: 3,
          week: "三",
        },
        {
          id: 4,
          week: "四",
        },
        {
          id: 5,
          week: "五",
        },
        {
          id: 6,
          week: "六",
        },
        {
          id: 7,
          week: "日",
        },
      ],
      rules: {
        name: [
          { required: true, message: "请输入礼包名称", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "礼包名称长度不能超过15个字符",
            trigger: "blur",
          },
        ],
        description: [
          { required: true, message: "请输入礼包描述", trigger: "blur" },
          {
            min: 1,
            max: 500,
            message: "礼包类型描述长度不能超过500个字符",
            trigger: "blur",
          },
        ],
        giftClassify: [
          { required: true, message: "请选择礼包类型", trigger: "change" },
        ],
        // giftValue: [
        //   { required: true, message: "请输入礼包价值", trigger: "blur" },
        // ],
      },
      weekDayList: [1, 2, 3, 4, 5, 6, 7],
      weekIndeterminate: false,
      ischeckAllWeek: true,
      giftDetailOptions: [],
      cascaderValue: [
        {
          cascaderVal: [],
          value: "",
        },
      ],
    };
  },
  created() {
    this.getGiftDetailOptions();
  },
  methods: {
    open(roomSonId, row) {
      console.log(row);
      // this.$refs.form.resetFields()//清空所有的验证规则
      if (row) {
        this.elongGiftInfo = row;
        this.elongGiftInfo.date = [row.start, row.end];
        this.elongGiftInfo.weeklyIndex = row.weeklyIndex;
        let weeklyIndexList = row.weeklyIndex.split("");
        this.weekDayList = [];
        for (let i = 0; i < weeklyIndexList.length; i++) {
          if (weeklyIndexList[i] == "1") {
            this.weekDayList.push(i + 1);
          }
        }
        if (this.weekDayList.length == 7) {
          this.ischeckAllWeek = true;
        } else if (this.weekDayList.length == 0) {
          this.ischeckAllWeek = false;
        } else {
          this.weekIndeterminate = true;
        }

        this.elongGiftInfo.giftDetail = JSON.parse(row.giftDetails);
        this.cascaderValue = [];
        this.elongGiftInfo.giftDetail.forEach((item) => {
          console.log(item);
          this.giftDetailOptions.forEach((item2, index2) => {
            if (
              item2.detailName == item.detailName &&
              item2.detailType == item.detailType
            ) {
              let subDetailObj = this.giftDetailOptions[index2].subDetail;
              subDetailObj.forEach((item3, index3) => {
                if (
                  item3.code == item.subDetail[0].code &&
                  item3.subName == item.subDetail[0].subName
                ) {
                  let cascaderValObj = {
                    cascaderVal: [index2, index3],
                    value: item.subDetail[0].value,
                  };
                  this.cascaderValue.push(cascaderValObj);
                }
              });
            }
          });
        });
      } else {
        this.elongGiftInfo = {
          name: "", //礼包名称，长度不能超过15 个字符
          description: "", //礼包类型描述，长度不能超 过500个字符
          end: "", //入住有效日期 结束 2021-03-24 00:00:00
          giftClassify: 0, //礼包类型 0-礼包 1-套餐
          giftDetail: [
            {
              detailName: "",
              detailType: "",
              subDetail: [{ code: "", subName: "", value: "" }],
            },
          ], //礼包详情
          giftDetails: "", //礼包内容 至少选择一个类型；所有类型价值总和等于总价值，有校验，不等则当错误数据处理
          giftValue: "", //总价值 上限1500
          giveType: 0, //赠送方式 0-每间房包含一次、1-每间房每日包含、2-其他
          giveTypeRemark: "", //GiveType=2 的时候自定义 此参数
          id: "",

          roomSonId: "", //售卖房型id
          start: "", //入住有效日期 开始 2021-03-15 00:00:00
          validType: 1, //生效类型 1:入住生效 2:离 店生效 3:在店生效
          weeklyIndex: "1111111", //适应日期 1111111 第一位为0表示周一不可用
          date: [],
        };
        this.weekDayList = [1, 2, 3, 4, 5, 6, 7];
        this.weekIndeterminate = false;
        this.ischeckAllWeek = true;
        this.cascaderValue = [
          {
            cascaderVal: [],
            value: "",
          },
        ];
        this.elongGiftInfo.roomSonId = roomSonId;
      }
      this.modalVisible = true;
    },
    changeCheckAllWeek(val) {
      this.weekIndeterminate = false;
      this.ischeckAllWeek = val;
      if (val) {
        this.weekDayList = [1, 2, 3, 4, 5, 6, 7];
      } else {
        this.weekDayList = [];
      }
    },
    changeWeekDayList(val) {
      if (val.length == 0) {
        this.weekIndeterminate = false;
        this.ischeckAllWeek = false;
      } else if (val.length == 7) {
        this.weekIndeterminate = false;
        this.ischeckAllWeek = true;
      } else {
        this.ischeckAllWeek = false;
        this.weekIndeterminate = true;
      }
    },
    cancel() {
      //   this.elongGiftInfo = {
      //     name: "", //礼包名称，长度不能超过15 个字符
      //     description: "", //礼包类型描述，长度不能超 过500个字符
      //     end: "", //入住有效日期 结束 2021-03-24 00:00:00
      //     giftClassify: "", //礼包类型 0-礼包 1-套餐
      //     giftDetail: [
      //       {
      //         detailName: "",
      //         detailType: "",
      //         subDetail: [{ code: "", subName: "", value: "" }],
      //       },
      //     ], //礼包详情
      //     giftDetails: "", //礼包内容 至少选择一个类型；所有类型价值总和等于总价值，有校验，不等则当错误数据处理
      //     giftValue: "", //总价值 上限1500
      //     giveType: 0, //赠送方式 0-每间房包含一次、1-每间房每日包含、2-其他
      //     giveTypeRemark: "", //GiveType=2 的时候自定义 此参数
      //     id: "",

      //     roomSonId: "", //售卖房型id
      //     start: "", //入住有效日期 开始 2021-03-15 00:00:00
      //     validType: 1, //生效类型 1:入住生效 2:离 店生效 3:在店生效
      //     weeklyIndex: "1111111", //适应日期 1111111 第一位为0表示周一不可用
      //     date: [],
      //   };
      //   this.weekDayList = [1, 2, 3, 4, 5, 6, 7];
      //   this.weekIndeterminate = false;
      //   this.ischeckAllWeek = true;
      //   this.giftDetailOptions = [];
      //   this.cascaderValue = [
      //     {
      //       cascaderVal: [],
      //       value: "",
      //     },
      //   ];
      // this.$refs.form.resetFields()//清空所有的验证规则
      this.modalVisible = false;
      //   const initialData = JSON.parse(
      //     JSON.stringify(this.$options.data.call(this))
      //   );
      //   Object.assign(this.$data, initialData);
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.elongGiftInfo);
          if (this.weekDayList.length == 0) {
            this.$message.warning("请选择适用星期");
            return;
          }
          this.elongGiftInfo.weeklyIndex = "";
          if (this.weekDayList.includes(1)) {
            this.elongGiftInfo.weeklyIndex = "1";
          } else {
            this.elongGiftInfo.weeklyIndex = "0";
          }
          for (let i = 2; i <= 7; i++) {
            if (this.weekDayList.includes(i)) {
              this.elongGiftInfo.weeklyIndex += "1";
            } else {
              this.elongGiftInfo.weeklyIndex += "0";
            }
          }
          console.log(this.elongGiftInfo.weeklyIndex);
          if (this.elongGiftInfo.giveType == 2) {
            if (this.elongGiftInfo.giveTypeRemark == "") {
              this.$message.warning("请输入赠送方式备注");
              return;
            }
          }
          if (this.elongGiftInfo.date.length == 0) {
            this.$message.warning("请选择适用日期");
            return;
          }
          this.elongGiftInfo.start = this.elongGiftInfo.date[0];
          this.elongGiftInfo.end = this.elongGiftInfo.date[1];

          for (let i = 0; i < this.cascaderValue.length; i++) {
            if (this.cascaderValue[i].cascaderVal.length == 0) {
              this.$message.warning("请选择礼包内容");
              return;
            }
            if (this.cascaderValue[i].value == "") {
              this.$message.warning("请输入礼包价值");
              return;
            }
          }
          this.elongGiftInfo.giftDetail = [];
          this.elongGiftInfo.giftValue = 0;
          this.cascaderValue.forEach((item) => {
            let itemObj = {
              detailName:
                this.giftDetailOptions[item.cascaderVal[0]].detailName,
              detailType:
                this.giftDetailOptions[item.cascaderVal[0]].detailType,
              subDetail: [
                {
                  code: this.giftDetailOptions[item.cascaderVal[0]].subDetail[
                    item.cascaderVal[1]
                  ].code,
                  subName:
                    this.giftDetailOptions[item.cascaderVal[0]].subDetail[
                      item.cascaderVal[1]
                    ].subName,
                  value: item.value,
                },
              ],
            };
            this.elongGiftInfo.giftDetail.push(itemObj);
            this.elongGiftInfo.giftValue += parseInt(item.value);
          });
          console.log(this.elongGiftInfo);
          if (this.elongGiftInfo.giftValue > 1500) {
            this.$message.warning("总价值不能超过1500");
            return;
          }
          addElongGift_api(this.elongGiftInfo).then((res) => {
            this.$message.success("添加成功");
            this.$parent.getElongGiftList();
            this.modalVisible = false;
          });
        }
      });
    },
    getGiftDetailOptions() {
      giftDetailOptions_api().then((res) => {
        res.data.forEach((item, index) => {
          this.$set(item, "subName", item.detailName);
          this.$set(item, "index", index);
          item.subDetail.forEach((item2, index2) => {
            this.$set(item2, "index", index2);
          });
        });
        this.giftDetailOptions = res.data;

        // console.log(res.data);
      });
    },
    changeCascaderValue(val) {
      console.log(val);
    },
    addGiftDetail(index) {
      this.cascaderValue.splice(index + 1, 0, { cascaderVal: [], value: "" });
    },
    removeGiftDetail(index) {
      this.cascaderValue.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
