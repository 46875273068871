<template>
  <div>
    <el-dialog
      title="酒店销售列表"
      :visible.sync="dialogFormVisible"
      width="800px"
      :close-on-click-modal="false"
      @closed="closeDia"
    >
      <el-table :data="hotelSaleList" style="width: 100%" max-height="800">
        <el-table-column prop="accountId" label="手机号"  align="center"></el-table-column>
        <el-table-column
          prop="commissionRatio"
          label="提成比例"
          align="center"
        ></el-table-column>
        <el-table-column
        align="center"
          prop="thirdHotelId"
          label="第三方酒店id"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="delSale(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDia">确 定</el-button>
       
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getHotelSaleList_api,deleteHotelSale_api } from "@/apis/room";
export default {
  name: "Hotelsalelistdia",
  data() {
    return {
      dialogFormVisible: false,
      hotelSonId: "",
      hotelSaleList: [],
    };
  },
  methods: {
    open(hotelSonId) {
      this.hotelSonId = hotelSonId;
      this.getHotelSaleList();
    },
    getHotelSaleList() {
      getHotelSaleList_api({
        hotelSonId: this.hotelSonId,
      }).then((res) => {
        this.hotelSaleList = res.data;
        this.dialogFormVisible = true;
      });
    },
    closeDia() {
      this.dialogFormVisible = false;
    },
    delSale(row) {
      this.$confirm("此操作将永久删除该销售, 是否继续?", "提示")
        .then(() => {
            deleteHotelSale_api({
                id:row.id
            })
            .then(res=>{
                this.$message({
                    type:"success",
                    message:"删除成功"
                })
                this.getHotelSaleList()
            })
        })
        .catch(() => {
            this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
