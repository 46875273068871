<template>
  <!-- 新增售卖房型 -->
  <div>
    <el-dialog
      title="新增售卖房型"
      width="500px"
      :visible.sync="dialogFormVisible"
      top="10rem"
      :close-on-click-modal="false"
      @closed="cleanDataFn"
    >
      <!-- <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="6"> 物理房型 </el-col>
        <el-col :span="18">
          <el-input
            class="width140"
            size="mini"
            v-model="addData.roomName"
          ></el-input>
        </el-col>
      </el-row> -->
      <!-- <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="6">床型名称</el-col>
        <el-col :span="18">
          <el-select size="mini" class="width140" v-model="addData.bedType">
            <el-option
              v-for="item in bedTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <!-- <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="6">床型数量</el-col>
        <el-col :span="18">
          <el-select size="mini" class="width140" v-model="addData.bedTotal">
            <el-option
              v-for="item in bedTotalOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <!-- <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="6">床宽</el-col>
        <el-col :span="18">
          <el-input
            class="width140"
            size="mini"
            v-model="addData.bedSize"
          ></el-input>
        </el-col>
      </el-row> -->
      <!-- <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="8">房型名称备注</el-col>
        <el-col :span="16">
          <el-input
            class="width140"
            size="mini"
            v-model="addData.one"
          ></el-input>
        </el-col>
      </el-row> -->
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="6">入住人数</el-col>
        <el-col :span="18">
          <el-select
            class="width140"
            size="mini"
            v-model="addData.maxOccupancy"
          >
            <el-option
              v-for="item in maxOccupancyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- <el-input
            class="width140"
            size="mini"
            v-model="addData.maxOccupancy"
          ></el-input> -->
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="6">餐食</el-col>
        <el-col :span="18">
          <el-select class="width140" size="mini" v-model="addData.mealType">
            <el-option
              v-for="item in mealsNumbers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="6">餐食量</el-col>
        <el-col :span="18">
          <el-select class="width140" size="mini" v-model="addData.mealCount">
            <el-option
              v-for="item in canshiNumbers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="6">限定人群</el-col>
        <el-col :span="18">
          <el-select class="width140" size="mini" v-model="addData.limitation">
            <el-option
              v-for="item in shiyongNumbers"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margintop20" v-if="platform == 3">
        <el-col class="leftlabelbox" :span="6">是否钟点房</el-col>
        <el-col :span="18">
          <el-select
            class="width140"
            size="mini"
            v-model="addData.isHourlyRoom"
          >
            <el-option
              v-for="item in isHourlyRoomOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margintop20" v-if="addData.isHourlyRoom">
        <el-col class="leftlabelbox" :span="6">最早入住时间</el-col>
        <el-col :span="18">
          <el-select
            class="width140"
            size="mini"
            placeholder="10:00"
            v-model="addData.earliestArriveTime"
          >
            <el-option
              v-for="item in timers"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margintop20" v-if="addData.isHourlyRoom">
        <el-col class="leftlabelbox" :span="6">最晚离店时间</el-col>
        <el-col :span="18">
          <el-select
            size="mini"
            class="width140"
            placeholder="18:00"
            v-model="addData.latestDepartureTime"
          >
            <el-option
              v-for="item in timers"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margintop20" v-if="addData.isHourlyRoom">
        <el-col class="leftlabelbox" :span="6">入住小时数</el-col>
        <el-col :span="18">
          <el-input
            size="mini"
            class="width140"
            v-model="addData.stayHours"
            placeholder="2小时"
          ></el-input>
        </el-col>
      </el-row>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addorUpRoom_api } from "../../../apis/room";
export default {
  data() {
    return {
      dialogFormVisible: false,
      addData: {
        bedSize: "",
        bedType: "",
        ctripHotelId: "",
        hotelSonId: "",
        id: "",
        limitation: "",
        maxOccupancy: "",
        mealCount: "",
        mealType: "",
        roomName: "",
        roomTypeId: "",
        bedTotal: "",
        isHourlyRoom: false,
        earliestArriveTime: "",
        latestDepartureTime: "",
        stayHours: "",
      },
      personNumbers: [
        { label: "不限", value: "不限" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
      ],
      mealsNumbers: [
        { label: "无早", value: 0 },
        { label: "早餐", value: 4 },
        { label: "早+晚餐", value: 5 },
        { label: "早+午餐", value: 6 },
        { label: "早+午+晚餐", value: 7 },
        { label: "午餐", value: 2 },
        { label: "午+晚餐", value: 3 },
        { label: "晚餐", value: 1 },
        { label: "午/晚二选一", value: 10 },
        { label: "早+ 午/晚二选一", value: 11 },
      ],
      shiyongNumbers: [
        { label: "不限", value: 0 },
        { label: "外宾", value: 1 },
        { label: "中宾", value: 2 },
        { label: "内宾", value: 3 },
        { label: "男生入住", value: 4 },
        { label: "女生入住", value: 5 },
        { label: "混合入住", value: 6 },
      ],
      canshiNumbers: [
        { label: "0", value: 0 },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
        { label: "11", value: 11 },
        { label: "12", value: 12 },
        { label: "13", value: 13 },
        { label: "14", value: 14 },
        { label: "15", value: 15 },
        { label: "16", value: 16 },
        { label: "17", value: 17 },
        { label: "18", value: 18 },
        { label: "19", value: 19 },
        { label: "20", value: 20 },
        { label: "21", value: 21 },
        { label: "22", value: 22 },
        { label: "23", value: 23 },
        { label: "24", value: 24 },
        { label: "25", value: 25 },
        { label: "26", value: 26 },
        { label: "27", value: 27 },
        { label: "28", value: 28 },
        { label: "29", value: 29 },
        { label: "30", value: 30 },
        { label: "31", value: 31 },
        { label: "32", value: 32 },
        { label: "33", value: 33 },
        { label: "34", value: 34 },
        { label: "35", value: 35 },
        { label: "36", value: 36 },
        { label: "37", value: 37 },
        { label: "38", value: 38 },
        { label: "39", value: 39 },
        { label: "40", value: 40 },
        { label: "41", value: 41 },
        { label: "42", value: 42 },
        { label: "43", value: 43 },
        { label: "44", value: 44 },
        { label: "45", value: 45 },
        { label: "46", value: 46 },
        { label: "47", value: 47 },
        { label: "48", value: 48 },
        { label: "49", value: 49 },
        { label: "50", value: 50 },
      ],
      bedTypeOptions: [
        { label: "大床", value: 1 },
        { label: "特大床", value: 2 },
        { label: "双人床", value: 3 },
        { label: "单人床", value: 4 },
        { label: "圆床", value: 5 },
        { label: "三人床", value: 6 },
        { label: "上下铺", value: 7 },
        { label: "榻榻米", value: 8 },
      ],
      bedTotalOptions: [
        { label: "1张", value: 1 },
        { label: "2张", value: 2 },
        { label: "3张", value: 3 },
        { label: "4张", value: 4 },
        { label: "5张", value: 5 },
        { label: "6张", value: 6 },
        { label: "7张", value: 7 },
        { label: "8张", value: 8 },
        { label: "9张", value: 9 },
        { label: "10张", value: 10 },
        { label: "11张", value: 11 },
        { label: "12张", value: 12 },
        { label: "13张", value: 13 },
        { label: "14张", value: 14 },
        { label: "15张", value: 15 },
        { label: "16张", value: 16 },
        { label: "17张", value: 17 },
        { label: "18张", value: 18 },
        { label: "19张", value: 19 },
        { label: "20张", value: 20 },
      ],
      maxOccupancyOptions: [
        { label: "1人", value: 1 },
        { label: "2人", value: 2 },
        { label: "3人", value: 3 },
        { label: "4人", value: 4 },
        { label: "5人", value: 5 },
        { label: "6人", value: 6 },
        { label: "7人", value: 7 },
        { label: "8人", value: 8 },
        { label: "9人", value: 9 },
        { label: "10人", value: 10 },
        { label: "11人", value: 11 },
        { label: "12人", value: 12 },
        { label: "13人", value: 13 },
        { label: "14人", value: 14 },
        { label: "15人", value: 15 },
        { label: "16人", value: 16 },
        { label: "17人", value: 17 },
        { label: "18人", value: 18 },
        { label: "19人", value: 19 },
        { label: "20人", value: 20 },
      ],
      platform: "",
      isHourlyRoomOption: [
        { label: "否", value: false },
        { label: "是", value: true },
      ],
      timers: [
        { label: "00:00", id: 1 },
        { label: "01:00", id: 2 },
        { label: "02:00", id: 3 },
        { label: "03:00", id: 4 },
        { label: "04:00", id: 5 },
        { label: "05:00", id: 6 },
        { label: "06:00", id: 7 },
        { label: "07:00", id: 8 },
        { label: "08:00", id: 9 },
        { label: "09:00", id: 10 },
        { label: "10:00", id: 11 },
        { label: "11:00", id: 12 },
        { label: "12:00", id: 13 },
        { label: "13:00", id: 14 },
        { label: "14:00", id: 15 },
        { label: "15:00", id: 16 },
        { label: "16:00", id: 17 },
        { label: "17:00", id: 18 },
        { label: "18:00", id: 19 },
        { label: "19:00", id: 20 },
        { label: "20:00", id: 21 },
        { label: "21:00", id: 22 },
        { label: "22:00", id: 23 },
        { label: "23:00", id: 24 },
        { label: "24:00", id: 25 },
      ],
    };
  },

  methods: {
    open(data, platform) {
      console.log(data);
      this.platform = platform;
      this.addData.roomName = data.roomTypeName;
      this.addData.roomTypeId = data.roomTypeId;
      this.addData.ctripHotelId = data.ctripHotelId;
      this.addData.hotelSonId = data.hotelSonId;
      this.addData.bedSize = data.bedSize;
      this.addData.maxOccupancy = Number(data.maxOccupancy);
      this.addData.bedType = data.bedType;
      this.addData.bedTotal = data.bedTotal;
      this.addData.isHourlyRoom = false;
      this.addData.earliestArriveTime = "";
      this.addData.latestDepartureTime = "";
      this.addData.stayHours = "";

      // this.addData = data;
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
      if (!this.addData.bedType) {
        this.$alert("请填写售卖房型基础信息");
      } else {
        this.setAddorUpRoom();
      }
    },
    async setAddorUpRoom() {
      let { code, data } = await addorUpRoom_api(this.addData);
      if (code == 0) {
        this.$message.success(data);
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById();
      }
    },
    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.margintop20 {
  margin-top: 20px;
}
.leftlabelbox {
  text-align: right;
  height: 28px;
  line-height: 28px;
  padding-right: 10px;
}
.width140 {
  width: 300px;
}
</style>>

