import { render, staticRenderFns } from "./AddSellRoom.vue?vue&type=template&id=67f17dcd&scoped=true&"
import script from "./AddSellRoom.vue?vue&type=script&lang=js&"
export * from "./AddSellRoom.vue?vue&type=script&lang=js&"
import style0 from "./AddSellRoom.vue?vue&type=style&index=0&id=67f17dcd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f17dcd",
  null
  
)

export default component.exports