<template>
  <div>
    <el-dialog
      title="礼包管理"
      :visible="modalVisible"
      width="999px"
     
      @close="closeModal"
    >
      <el-button type="primary" @click="openAddElongGiftModal(undefined)">
        新增礼包
      </el-button>
      <el-table :data="elongGiftList" style="width: 100%">
        <el-table-column label="礼包名称" prop="name"></el-table-column>
        <el-table-column label="描述" prop="description"></el-table-column>
        <el-table-column label="总价值" prop="giftValue"></el-table-column>
        <el-table-column label="类型" prop="giftClassify">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.giftClassify == 0">礼包</span>
              <span v-if="scope.row.giftClassify == 1">套餐</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="赠送方式" prop="giveType">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.giveType == 0">每间房包含一次</span>
              <span v-if="scope.row.giveType == 1">每间房每日包含</span>
              <span v-if="scope.row.giveType == 2">{{
                scope.row.giveTypeRemark
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="openAddElongGiftModal(scope.row)"
              style="margin-bottom: 5px"
            >
              修改
            </el-button>
            <el-button
                type="text"
                @click="openSendElongGiftModal(scope.row)"
                style="margin-bottom: 5px"
              >
                发布
              </el-button>
            <el-button type="text" @click="delElongGift(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <Addelonggiftmodal ref="addelonggiftmodal"></Addelonggiftmodal>
    <Sendelonggiftmodal ref="sendelonggiftmodal"></Sendelonggiftmodal>
  </div>
</template>
  <script>
import { elongGiftList_api, delElongGift_api } from "@/apis/room";
import Addelonggiftmodal from "./Addelonggiftmodal";
import Sendelonggiftmodal from "./Sendelonggiftmodal";

export default {
  name: "Elonggiftmodal",
  components: {
    Addelonggiftmodal,
    Sendelonggiftmodal
  },

  data() {
    return {
      roomSonId: "",
      modalVisible: false,
      elongGiftList: [],
    };
  },
  methods: {
    open(item2) {
      console.log(item2);
      this.roomSonId = item2.roomVestId;
      this.getElongGiftList();
      this.modalVisible = true;
    },
    //根据售卖房型ID获取礼包列表
    getElongGiftList() {
      elongGiftList_api({
        roomSonId: this.roomSonId,
      }).then((res) => {
        if (res.code == 0) {
          this.elongGiftList = res.data;
        }
      });
    },
    //打开新增礼包弹窗
    openAddElongGiftModal(row) {
      this.$refs.addelonggiftmodal.open(this.roomSonId, row);
    },
    openSendElongGiftModal(row) {
      this.$refs.sendelonggiftmodal.open(row.id, this.roomSonId);
    },
    closeModal() {
      this.modalVisible = false;
    },
    delElongGift(row) {
      this.$confirm("确认删除该礼包吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delElongGift_api({
            id: row.id,
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getElongGiftList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
  <style lang="scss" scoped>
</style>
  