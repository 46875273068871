<template>
    <div>
      <el-dialog title="发布礼包" :visible.sync="modalVisible" width="600px">
        <el-checkbox-group v-model="roomIdsList">
          <!-- <el-row>
            <el-col :span="8" v-for="(item, index) in giftRoomList" :key="index">
              <el-checkbox :label="item.roomId">
                {{ item.roomName }}
              </el-checkbox>
            </el-col>
          </el-row> -->
          <el-checkbox
            :label="item.roomId"
            v-for="(item, index) in giftRoomList"
            :key="index"
          >
            {{ item.roomName }}
          </el-checkbox>
        </el-checkbox-group>
  
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="modalVisible = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import { sendElongGift_api, getGiftRoomList_api } from "@/apis/room";
  export default {
    name: "Sendelonggiftmodal",
    data() {
      return {
        roomSonId: "",
        handleData: {
          id: "",
          roomIds: "",
        },
        modalVisible: false,
        giftRoomList: [],
        roomIdsList: [],
      };
    },
    methods: {
      open(id, roomSonId) {
        this.roomSonId = roomSonId;
        this.handleData.id = id;
        this.getGiftRoomList();
        this.modalVisible = true;
      },
      getGiftRoomList() {
        getGiftRoomList_api({
          roomSonId: this.roomSonId,
        }).then((res) => {
          this.giftRoomList = res.data;
          this.roomIdsList = [];
          this.giftRoomList.forEach((item) => {
            this.roomIdsList.push(item.roomId);
          });
        });
      },
      submitForm() {
        if (this.roomIdsList.length == 0) {
          this.$message.error("请选择房间");
          return;
        }
        this.handleData.roomIds = this.roomIdsList.join(",");
        sendElongGift_api(this.handleData).then((res) => {
          this.$message.success("发布成功");
          this.modalVisible = false;
        });
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  </style>
  